import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, StyledEngineProvider, TextField, Tooltip, Typography } from '@mui/material';
import React, {useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData } from '../GenericFunctions/GenericFunctions';
import useViewAssetContext from '../../hooks/UseViewAssetContext';
import useCommentsContext from '../../hooks/UseCommentsContext';
import { AddLink } from '@mui/icons-material';


function AssetServiceCallLink({assetCode}) {
  const {setDoneUpdating} = useViewAssetContext();
  const {setDoneUpdate} = useCommentsContext();
  const axios = AxiosCustomConfig();
  const [open,setOpen] = useState(false)
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [serviceRequests, setserviceRequests] = useState([]);
  const [serviceRequestsLoading, setserviceRequestsLoading] = useState(false);
  const [OpenserviceRequests, setOpenserviceRequests] = useState(false);
  const [serviceRequestsData, setserviceRequestsData] = useState({
    asset_code: '',
    serviceRequests: [],
    distributor_company_id: '', 
    request_code: '',
  });

  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);

    setserviceRequestsData(
      (prevData) => ({ ...prevData, asset_code: assetCode})
    )
  }
  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!serviceRequestsData.request_code) {
        setOpenMessageDialog(true);
        setMessage('Please select one request.')
        return
    }
    
    setShowProgress(true);
    try {
        const options = {
            method: 'POST',
              url: '/ServiceRequests/addLinkForServiceRequests.json',
              data: objectToFormData(serviceRequestsData),
        }

        const response = await axios(options);
        const {is_success, message} = response.data;

        setShowProgress(false);

        if (is_success) {
          setDoneUpdating((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpenMessageDialog(true);
          setMessage(message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. There's something wrong with the server.`);
    }
  }

  const resetForm = () => {
    setserviceRequestsData({
      asset_code: '',
      serviceRequests: [],
      distributor_company_id: '', 
      request_code: ''
    });
  };

  const getServiceRequests = async(ignore) => {
    try {
      setserviceRequestsLoading(true)
        const options = {
            method: 'POST',
            url: '/serviceRequests/getServiceRequestsForLinking.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setserviceRequests(response.data);
        }
        setserviceRequestsLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!OpenserviceRequests) {
      setserviceRequests([]);
    }

    if (!OpenserviceRequests) return undefined
    getServiceRequests(ignore)

    return () => {
      ignore = true
    };
  }, [OpenserviceRequests]);


  const hanldeRequestChange = (e, selectValue) => {
    if (selectValue) {
      return setserviceRequestsData(
        (prevAccount) => ({ ...prevAccount, serviceRequests: selectValue, distributor_company_id: selectValue.distributor_company_id, request_code: selectValue.request_code})
      )
    }

    setserviceRequestsData(
      (prevAccount) => ({ ...prevAccount, serviceRequests: [], distributor_company_id: '', request_code: ''})
    )
  }

  return (
    <div>
    <Tooltip title="Link" arrow>
      <IconButton
        onClick={(e) => handleOpen(e)}
        size="small"
        sx={{
          width: 35,
          height: 35,
          borderRadius: 1,
          backgroundColor: 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
      >
        <AddLink />
      </IconButton>
    </Tooltip>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Link Record</DialogTitle>
      <DialogContent>
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={OpenserviceRequests}
                  onOpen={() => {setOpenserviceRequests(true);}}
                  onClose={() => {setOpenserviceRequests(false);}}
                  loading={serviceRequestsLoading}
                  onChange={hanldeRequestChange}
                  options={serviceRequests}
                  value={serviceRequestsData.serviceRequests}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Request*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {serviceRequestsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => {
              setOpenMessageDialog(false)
              setOpen(false)
              }}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default AssetServiceCallLink