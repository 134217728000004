import React, { useState } from 'react';
import { Grid, Paper, Box, Typography, Button, Modal, IconButton, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';

function DisposalImageReceipts({ disposalImagesReceipts }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const isPdf = (file) => file.split('.').pop().toLowerCase() === 'pdf';

  const handleFileClick = (index) => {
    setSelectedFile(disposalImagesReceipts[index]);
    setCurrentIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNextFile = () => {
    const nextIndex = (currentIndex + 1) % disposalImagesReceipts.length;
    setSelectedFile(disposalImagesReceipts[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  const handlePrevFile = () => {
    const prevIndex = (currentIndex - 1 + disposalImagesReceipts.length) % disposalImagesReceipts.length;
    setSelectedFile(disposalImagesReceipts[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  return (
    <>
      <Box>
        <Typography mb={1}>Receipts:</Typography>
        <Box display="flex" flexDirection="row" flexWrap="nowrap" sx={{ width: '100%', height: 110 }}>
          {disposalImagesReceipts.map((file, index) => (
            <div
              key={file}
              style={{ width: 100, height: 100, flex: '0 0 auto', marginLeft: index > 0 ? '5px' : '0' }}
            >
              {isPdf(file) ? (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    backgroundColor: '#f5f5f5',
                    cursor: 'pointer',
                    border: '1px solid #ddd',
                  }}
                  onClick={() => handleFileClick(index)}
                >
                  <Typography variant="body2" color="primary" textAlign="center">
                    PDF File
                  </Typography>
                </Box>
              ) : (
                <img
                  style={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block', cursor: 'pointer' }}
                  src={file}
                  alt="after repair"
                  loading="lazy"
                  onClick={() => handleFileClick(index)}
                />
              )}
            </div>
          ))}
        </Box>
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxWidth: '90%',
            maxHeight: '90%',
            overflow: 'auto',
          }}
        >
          <IconButton
            aria-label="close"
            style={{ position: 'absolute', top: 0, right: 0 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          {selectedFile && (
            isPdf(selectedFile) ? (
              <Typography>
                <Link href={selectedFile} download target="_blank" rel="noopener noreferrer">
                  Download {selectedFile.split('/').pop()}
                </Link>
              </Typography>
            ) : (
              <img
                src={selectedFile}
                alt="after repair"
                style={{ maxWidth: '100%', maxHeight: '70vh', objectFit: 'contain' }}
              />
            )
          )}
          {currentIndex > 0 && (
            <IconButton
              disabled={currentIndex === 0}
              onClick={handlePrevFile}
              style={{
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(7, 11, 10, 0.5)',
              }}
            >
              <NavigateBefore style={{ color: 'white' }} />
            </IconButton>
          )}
          {currentIndex < disposalImagesReceipts.length - 1 && (
            <IconButton
              disabled={currentIndex === disposalImagesReceipts.length - 1}
              onClick={handleNextFile}
              style={{
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(7, 11, 10, 0.5)',
              }}
            >
              <NavigateNext style={{ color: 'white' }} />
            </IconButton>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default DisposalImageReceipts;
