import { CloudUpload, Edit } from '@mui/icons-material';
import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { objectToFormData, VisuallyHiddenInput } from '../GenericFunctions/GenericFunctions';
import useAuth from '../../hooks/UseAuth';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
function EditServiceRequest({requestDetails, setDoneUpdate}) {
  const {auth} = useAuth();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const [stores, setStores] = useState([]);
  const [openStore, setOpenStore] = useState(false);
  const [storeLoading, setStoreLoading] = useState(false);
  const [serviceRequest, setServiceRequest] = useState({
    assetSelected: [],
    asset_code: '',
    asset_name:'',
    warehouseSelected: [],
    warehouse_unique_code: '',
    warehouse_name: '',
    stores: [],
    account_code: '',
    account_name: '',
    branch_code: '',
    branch_name: '',
    complaints: '',
    request_code: '',
    servicesSelected: [],
    services: '',
    distributor_company_id: '',
    date_reported: '',
    tech_findings: '',
    equip_no_image: '',
    asset_image: '',
    assign_tech: '',
    completion_date: '',
  });

  const [openService, setOpenService] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [serviceLoading, setServiceLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true)
    const {request_code, complaints, asset_code, warehouse_unique_code, centigen_branch, account_code, store_name, asset_name, services, account_unique_code, distributor_company_id, date_reported, tech_findings, assign_tech, completion_date} = requestDetails;

    const validDateReported = date_reported 
      ? dayjs(date_reported, "YYYY-MM-DD").isValid() 
        ? dayjs(date_reported, "YYYY-MM-DD").format('YYYY-MM-DD') 
        : null 
      : null;

    const validCompletionDate = completion_date 
      ? dayjs(completion_date, "YYYY-MM-DD").isValid() 
        ? dayjs(completion_date, "YYYY-MM-DD").format('YYYY-MM-DD') 
        : null 
      : null;

    const servicesArray = services
        ? services.split(',').map(item => {
            const trimmedItem = item.trim();
            return {
                label: trimmedItem,
                service_code: trimmedItem,
            };
        })
        : [];

    setServiceRequest((prevData) => ({ 
      ...prevData, 
      complaints: complaints,
      request_code: request_code,
      assetSelected: {'label' : asset_code, value: asset_code},
      warehouseSelected: {'label' : `${centigen_branch} - (${warehouse_unique_code})`, value: warehouse_unique_code},
      stores: {'label' : `${store_name} - (${account_code})`, value: account_code},
      servicesSelected: servicesArray,
      asset_code: asset_code,
      asset_name: asset_name,
      warehouse_unique_code: warehouse_unique_code,
      warehouse_name: centigen_branch,
      account_unique_code: account_unique_code,
      distributor_company_id: distributor_company_id,
      services: services,
      date_reported: validDateReported, // Check if valid
      tech_findings: tech_findings,
      assign_tech: assign_tech,
      completion_date: validCompletionDate,
    }))
  }

  const handleClose = () => {
    setOpen(false)
  }
  const hanldeAssetsChange = (e, selectValue) => {
    if (selectValue) {
      return setServiceRequest(
        (prevAccount) => ({ ...prevAccount, assetSelected: selectValue, asset_code: selectValue.asset_code, asset_name: selectValue.asset_name})
      )
    } 

    setServiceRequest((prevAccount) => ({ ...prevAccount, assetSelected: [], asset_code: '', asset_name: ''}))
  }

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setServiceRequest(
        (prevAccount) => ({ ...prevAccount, warehouseSelected: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name})
      )
    } 

    setServiceRequest((prevAccount) => ({ ...prevAccount, warehouseSelected: [], warehouse: []}))
  }

  const hanldeServicesChange = (e, selectValue) => {
    if (selectValue) {
      let services = selectValue.map(item => item.service_code);
      return setServiceRequest((prevAccount) => ({ ...prevAccount, servicesSelected: selectValue,  services: services}))
    } 
    
    setServiceRequest((prevAccount) => ({ ...prevAccount, servicesSelected: [], services: []}))
  }

  const hanldeStoreChange = (e, selectValue) => {
    if (selectValue) {
      return setServiceRequest(
        (prevAccount) => ({ 
          ...prevAccount, 
          stores: selectValue, 
          account_unique_code: selectValue.account_unique_code,
          distributor_company_id: selectValue.distributor_company_id,
        })
      )
    } 

    setServiceRequest((prevAccount) => ({ ...prevAccount, stores: [], account_code: '', branch_code: ''}))
  }



  const getWarehouses = async(ignore) => {
    try {
      setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setWarehouses(response.data);
        }
        setWarehouseLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  
  const getAssets = async(ignore) => {
    try {
      setLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsRegistry/getAssetsForServiceRequest.json',
            data: {warehouse_unique_code: serviceRequest.warehouse_unique_code}
        }
        const response = await axios(options);
        if (!ignore) {
          setAssets(response.data);
        }
        setLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getStore = async (ignore) => {
    setStoreLoading(true)
    try {
        const options = {
            method: 'POST',
            url: '/StoreAssets/getStoreforServiceRequest.json',
            data: {asset_code: serviceRequest.asset_code}
        }

        const response = await axios(options);
        if (!ignore) {
          setStores(response.data);
          setStoreLoading(false)
        }
        

    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  const getServices= async(ignore) => {
    try {
      setServiceLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetServices/getAllServicesForAutoCompleteAllSerivceCall.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setServicesData(response.data);
        }
        setServiceLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;

    if (!openService) return undefined
    getServices(ignore)

    return () => {
      ignore = true
    };
  }, [openService]);

  useEffect(() => {
    let ignore = false;
    if (!openAssets) {
      setAssets([]);
    }

    if (!openAssets) return undefined
    getAssets(ignore)

    return () => {
      ignore = true
    };
  }, [openAssets]);

  useEffect(() => {
    let ignore = false;
    if (!openWarehouse) {
      setWarehouses([]);
    }

    if (!openWarehouse) return undefined
    getWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openWarehouse]);

  useEffect(() => {
    let ignore = false;
    if (!openStore) {
      setStores([]);
    }

    if (!openStore) return undefined
    getStore(ignore)

    return () => {
      ignore = true
    };
  }, [openStore]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowProgress(true);
    try {

        const options = {
            method: 'POST',
              url: '/ServiceRequests/UpdateServiceRequests.json',
              data: objectToFormData(serviceRequest),
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          setDoneUpdate(prev => !prev)
          resetForm();
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }
  const resetForm = () => {
    setServiceRequest({
      assetSelected: [],
      asset_code: '',
      asset_name:'',
      warehouseSelected: [],
      warehouse_unique_code: '',
      warehouse_name: '',
      stores: [],
      account_code: '',
      account_name: '',
      branch_code: '',
      branch_name: '',
      complaints: '',
      request_code: '',
      servicesSelected: [],
      services: '',
      distributor_company_id: '',
      date_reported: '',
      tech_findings: '',
      equip_no_image: '',
      asset_image: '',
      assign_tech: '',
      completion_date: '',
    })
  }

  const handleEquipmentNoImageChange = (event) => {
    const file = event.target.files[0];
    setServiceRequest((prev) => ({...prev, equip_no_image: file}));
  };

  const handleAssetImageChange = (event) => {
    const file = event.target.files[0];
    setServiceRequest((prev) => ({...prev, asset_image: file}));
  };

  return (
    <>
      <Button 
        disabled={requestDetails.status === 2 || requestDetails.status === 1 || requestDetails.status === 4 || requestDetails.status === 5 || requestDetails.username !== auth.username ? true : false}
        variant='contained' 
        size="small" 
        color='info' 
        startIcon={<Edit />}
        onClick={handleOpen}
      >Edit
      </Button>

      <Dialog
        open={open}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
        fullWidth={true} 
        maxWidth={'md'}
      >
        <DialogTitle>Update Service request</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={1}>
          <Grid item xs={12}>
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Date reported*"
                        value={serviceRequest.date_reported ? dayjs(serviceRequest.date_reported) : null}
                        onChange={(newValue) => setServiceRequest({...serviceRequest, date_reported: dayjs(newValue).format('YYYY-MM-DD')})}
                    />
                </LocalizationProvider>
            </FormControl>
          </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={openWarehouse}
                  onOpen={() => {setOpenWarehouse(true);}}
                  onClose={() => {setOpenWarehouse(false);}}
                  loading={warehouseLoading}
                  onChange={hanldeWarehouseChange}
                  options={warehouses}
                  value={serviceRequest.warehouseSelected}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Centigen Branch*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={openAssets}
                  onOpen={() => {setOpenAssets(true);}}
                  onClose={() => {setOpenAssets(false);}}
                  loading={loading}
                  onChange={hanldeAssetsChange}
                  options={assets}
                  value={serviceRequest.assetSelected}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Asset for Disposal*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  id="combo-box-demo"
                  open={openStore}
                  onOpen={() => {setOpenStore(true);}}
                  onClose={() => {setOpenStore(false);}}
                  loading={storeLoading}
                  onChange={hanldeStoreChange}
                  options={stores}
                  value={serviceRequest.stores}
                  noOptionsText={'No Available Data'}
                  getOptionLabel={(option) => option.label || ''}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Store*"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {storeLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography>Complaints:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Input complaints" 
                  value={serviceRequest.complaints}
                  onChange={(e) => setServiceRequest((prevAccount) => ({ ...prevAccount, complaints: e.target.value }))}
                />
            </Grid>

            <Grid item xs={12}>
              <Typography>Technician findings*:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Input findings" 
                  value={serviceRequest.tech_findings}
                  onChange={(e) => setServiceRequest((prevAccount) => ({ ...prevAccount, tech_findings: e.target.value }))}
                />
            </Grid>

            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Equipment no. image
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleEquipmentNoImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {serviceRequest.equip_no_image && (
                  <p>{serviceRequest.equip_no_image.name}</p>
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems="center" spacing={2} >
              <Grid item>
                <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                  Asset image*
                  <VisuallyHiddenInput type="file" accept="image/*" onChange={handleAssetImageChange}/>
                </Button>
              </Grid>
              <Grid item>
                {serviceRequest.asset_image && (
                  <p>{serviceRequest.asset_image.name}</p>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      disableCloseOnSelect
                      multiple
                      open={openService}
                      onOpen={() => {setOpenService(true);}}
                      onClose={() => {setOpenService(false);}}
                      onChange={hanldeServicesChange}
                      options={servicesData}
                      value={serviceRequest.servicesSelected}
                      loading={serviceLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Service*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {serviceLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="assign_tech"
                label="Assigned Technician"
                fullWidth
                onChange={(e) => setServiceRequest((prevAccount) => ({ ...prevAccount, assign_tech: e.target.value }))}
                value={serviceRequest.assign_tech}
            />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker 
                          label="Completion date"
                          value={serviceRequest.completion_date ? dayjs(serviceRequest.completion_date) : null}
                          onChange={(newValue) => setServiceRequest({...serviceRequest, completion_date: dayjs(newValue).format('YYYY-MM-DD')})}
                      />
                  </LocalizationProvider>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default EditServiceRequest