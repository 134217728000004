import { styled } from "@mui/material";

const isValidSpecialCharacter = (str) => {
  // Define your logic to validate if a string contains special characters
  // For example, you can use a regular expression
  const specialCharacters = /^[a-zA-Z0-9.\-@&/#$*()%ñÑ+'":;~\\_[\],=|?{} \s]+$/;
  return specialCharacters.test(str);
};

const isValidEmail = (email) => {
  // Define your logic to validate an email address
  // For example, you can use a regular expression
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

const objectToFormData = (object) => {
  const formData = new FormData();
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      formData.append(key, object[key]);
    }
  }

  return formData;
};

const validateAlphaNumeric = (string) => {
  let pattern = /^[a-zA-Z0-9-]*$/;

  return pattern.test(string);
};

const trimWhitespaces = (string) => {
  return string.replace(/^\s+|\s+$/gm,'').replace(/\s+/g, ' ').trim();
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

/**
 * Validate uploaded files against size constraints
 * @param {Array} files - Array of files to validate
 * @param {number} maxSize - Maximum allowed file size in bytes
 * @returns {Object} - An object with a boolean `isValid` and an array of `invalidFiles`
 */
const validateFileSizes = (files, maxSize = 2 * 1024 * 1024) => {
  if (!Array.isArray(files) || files.length === 0) {
    return {
      isValid: true, // No files to validate means no invalid files
      invalidFiles: [],
    };
  }

  // Initialize an array to hold invalid files
  const invalidFiles = [];

  // Loop through each file and check if its size exceeds the limit
  files.forEach((file) => {
    if (file.size > maxSize) {
      invalidFiles.push(file); // Add to invalidFiles if it exceeds the size limit
    }
  });

  return {
    isValid: invalidFiles.length === 0, // Validation fails if any file exceeds the limit
    invalidFiles, // Array of files that are too large
  };
};

/**
 * Validate uploaded files against allowed extensions
 * @param {Array} files - Array of files to validate
 * @param {Array} allowedExtensions - Array of allowed file extensions (e.g., ['jpg', 'jpeg', 'png', 'webp'])
 * @returns {Object} - An object with a boolean `isValid` and an array of `invalidFiles`
 */
const validateFileExtensions = (files = [], allowedExtensions = ['JPG', 'jpg', 'jpeg', 'png', 'webp']) => {
  
  
  if (!Array.isArray(files) || files.length === 0) {
    return {
      isValidExtension: true, // No files to validate means no invalid files
      invalidFilesExtension: [],
    };
  }

  // Initialize an array to hold invalid files
  const invalidFilesExtension = [];

  // Loop through each file and check if its extension is valid
  files.forEach((file) => {
    const fileExtension = file.name.split('.').pop().toLowerCase(); // Get the file extension
    if (!allowedExtensions.includes(fileExtension)) {
      invalidFilesExtension.push(file); // Add to invalidFilesExtension if it does not match allowed extensions
    }
  });
  
  return {
    isValidExtension: invalidFilesExtension.length === 0, // Validation fails if any file does not match the allowed extensions
    invalidFilesExtension, // Array of files with invalid extensions
  };
};


export { isValidSpecialCharacter, isValidEmail, objectToFormData, validateAlphaNumeric, trimWhitespaces, VisuallyHiddenInput, validateFileSizes, validateFileExtensions };
