import { Avatar, Box, Chip, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import MessageDialog from '../Utils/MessageDialog'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import useServiceRequestContext from '../../hooks/UseServiceRequestContext';
import AssetLink from './AssetLink';
import AssetUnlink from './AssetUnlink';

function AssetDetails({asset_code, requestCode}) {
  const axios = AxiosCustomConfig();
  const {doneUpdate} = useServiceRequestContext();
  const [assets, setAssets] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: 'code', headerName: 'Code', flex: 1, minWidth: 100, wrap: true,},
    { field: 'name', headerName: 'Name', width: 100,},
    { field: 'status_name', headerName: 'Status', flex: 1, minWidth: 100, wrap: true, },
    { field: 'asset_type_mame', headerName: 'Asset Type',flex: 1, minWidth: 100},
  ];
  const getAssets = async (ignore) => {
    try {
      setLoading(true);
      const options = {
        'method': 'POST',
        'url' : '/ServiceRequests/getAssetDetails.json',
        'data' : {asset_code : asset_code, request_code: requestCode}
      }
      const response = await axios(options);
      const data = response.data;
      if(!ignore) {
        setAssets(data)
        setLoading(false);
      }
    } catch (error) {
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
      if(!ignore) {
        setLoading(false);
      }
      
    }
  }

  useEffect(() => {
    let ignore = false
    getAssets(ignore)
    return ()=>{ignore=true} 
  }, [doneUpdate]);

  return (
    <Box>
    <Box p={1}>
      <Typography><b>Asset details</b></Typography>
    </Box>
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px'  }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <AssetLink requestCode={requestCode} />
        <AssetUnlink requestCode={requestCode} />
      </Box>
      
    </Box>
    <Box sx={{width: '100%', height: 100, display:'table', tableLayout: 'fixed'}} >
    {assets && 
      <DataGrid
        autoHeight
        keepNonExistentRowsSelected
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        loading={loading}
        rows={assets}
        columns={columns}
        getRowId={(row) => row.id}
        getRowHeight={() => 'auto'}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '15px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
        }}
        />
      }
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </Box>
  )
}


export default AssetDetails