import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, StyledEngineProvider, TextField, Typography } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { Close, CloudUpload } from '@mui/icons-material';
import styled from '@emotion/styled';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import ReactSignatureCanvas from 'react-signature-canvas';
import { isValidSpecialCharacter, trimWhitespaces } from '../GenericFunctions/GenericFunctions';
import { useTheme } from '@emotion/react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
function AddDisposalRequest({assetCode, setOnEdit}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const assetsLoading = openAssets && assets.length === 0;
  const [openSignature, setOpenSignature] = useState(false);
  const [disposalRequests, setDisposalRequests] = useState({
    assetSelected: [],
    assets: [],
    asset_image: [],
    remarks: '',
    store_contact_no: '',
    store_contact_name: '',
    signature_url: '',
    warehouseSelected: [],
    warehouse_unique_code: '',
    warehouse_name: '',
    date_disposed: '',
    date_approved: '',
    status_arr: [],
    status: '',
    status_name: '',
    fasr_file: '',
    receipts: '',
    scrap_buyer: '',
    scrap_proceeds: ''
  });

  const [warehouses, setWarehouses] = useState([]);
  const [warehouseLoading, setWarehouseLoading] = useState(false);
  const [openWarehouse, setOpenWarehouse] = useState(false);

  const handleOpenSignature= () => {
    setOpenSignature(true)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const signatureRef = useRef();
  const handleClose = () => {
    setOpen(false)
  }
  const handleSaveSignature = () => {
    const dataURL = signatureRef.current.toDataURL('image/png');
    if (dataURL) {

        setDisposalRequests((prevData) => ({ ...prevData, signature_url: dataURL}))
        setOpenSignature(false);
    }
    
  };

  const handleAssetsChange = (e, selectValue) => {
    if (selectValue && selectValue.length > 0) {
        const firstBranch = selectValue[0].warehouse_branch;
        const lastSelected = selectValue[selectValue.length - 1];

        // Check if the last added item's branch matches the first branch
        if (lastSelected.warehouse_branch !== firstBranch) {
            // Remove the last selected item from selectValue
            selectValue = selectValue.slice(0, -1);
            
            setOpenMessageDialog(true);
            setMessage('The last selected asset was removed because it belongs to a different warehouse branch.');
            return;
        }
        
        // Filter assets options to match only the first branch
        const filteredAssets = assets.filter(asset => asset.warehouse_branch === firstBranch);

        // Map the updated selectValue to get asset codes
        const assetCodes = selectValue.map(item => item.asset_code);

        // Update disposal requests and assets options
        setDisposalRequests(prevAccount => ({
            ...prevAccount,
            assetSelected: selectValue,
            assets: assetCodes
        }));
        setAssets(filteredAssets); // Update the Autocomplete options to show only matching branch assets
    } else {
        // Reset disposal requests and assets options when no items are selected
        setDisposalRequests(prevAccount => ({ ...prevAccount, assetSelected: [], assets: [] }));
        setAssets([]); // Reset options to initial assets list if needed
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setDisposalRequests((prevAccount) => ({ ...prevAccount, asset_image: [...prevAccount.asset_image, ...files]}))
  };

  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = disposalRequests.asset_image.filter((_, index) => index !== indexToRemove);
    
    // Update the state with the new array of images
    setDisposalRequests(prevState => ({
      ...prevState,
      asset_image: updatedImages
    }));
  };

  const getAssets = async(ignore) => {
    try {
      setLoading(true)
        const options = {
            method: 'POST',
            url: '/AssetsRegistry/getAssetsForDisposal.json',
            'data' : {'asset_code': assetCode}
        }
        const response = await axios(options);
        if (!ignore) {
          const filteredAssets = response.data.filter(asset => asset.warehouse_branch); // Filters out assets with a blank warehouse_branch
          setAssets(filteredAssets);
        }
        setLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const handleClearSignature = () => {
    signatureRef.current.clear();
  };

  useEffect(() => {
    let ignore = false;

    if (!assetsLoading) {
      return undefined;
    }

    getAssets(ignore);

    return () => {
      ignore = true;
    };

  }, [assetsLoading]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const trimmedremarks = trimWhitespaces(disposalRequests.remarks)
    

    if (!disposalRequests.scrap_buyer) {
      setOpenMessageDialog(true);
      setMessage('Scrap buyer is required')
      return
    }

    if (!disposalRequests.scrap_proceeds) {
      setOpenMessageDialog(true);
      setMessage('Scrap proceeds is required')
      return
    }

    if (!disposalRequests.fasr_file) {
      setOpenMessageDialog(true);
      setMessage('FASR is required')
      return
    }

    if (!disposalRequests.receipts) {
      setOpenMessageDialog(true);
      setMessage('Receipt is required')
      return
    }

    if (disposalRequests.asset_image.length === 0) {
      setOpenMessageDialog(true);
      setMessage('Please upload atleast 1 asset image.')
      return
    }

    if (!isValidSpecialCharacter(trimmedremarks) && trimmedremarks !== '') {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in remarks.')
      return
    }

    try {
      const formData = new FormData();
      if (disposalRequests.asset_image.length >  0) {
        disposalRequests.asset_image.forEach((file, index) => {
          formData.append(`asset_image_${index}`, file);
        });
      }

      // Add other fields to formData if needed
      formData.append('remarks', disposalRequests.remarks);
      formData.append('warehouse_unique_code', disposalRequests.warehouse_unique_code);
      formData.append('warehouse_name', disposalRequests.warehouse_name);
      formData.append('signature_url', disposalRequests.signature_url);
      formData.append('date_disposed', disposalRequests.date_disposed);
      formData.append('date_approved', disposalRequests.date_approved);
      formData.append('status', disposalRequests.status);
      formData.append('status_name', disposalRequests.status_name);
      formData.append('fasr_file', disposalRequests.fasr_file);
      formData.append('receipts', disposalRequests.receipts);
      formData.append('scrap_buyer', disposalRequests.scrap_buyer);
      formData.append('scrap_proceeds', disposalRequests.scrap_proceeds);

      const options = {
          method: 'POST',
            url: '/AssetsDisposal/addRequest.json',
            data: formData,
      }

      const response = await axios(options);
      setShowProgress(false);
      if (response.data.is_success) {
        resetForm();
        setOnEdit((prev) => !prev)
        setOpen(false)
        setOpenMessageDialog(true);
        setMessage(response.data.message)
      } else {
        setShowProgress(false)
        setOpenMessageDialog(true)
        setMessage(response.data.message)
      }
    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {

    setDisposalRequests({

      assetSelected: [],
      assets: [],
      asset_image: [],
      remarks: '',
      store_contact_name: '',
      store_contact_no: '',
      signature_url: '',
      warehouseSelected: [],
      warehouse_unique_code: '',
      warehouse_name: '',
      date_disposed: '',
      date_approved: '',
      status_arr: [],
      status: '',
      status_name: '',
      fasr_file: '',
      receipts: '',
      scrap_buyer: '',
      scrap_proceeds: ''
    });

  };

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      return setDisposalRequests(
        (prevAccount) => ({ ...prevAccount, warehouseSelected: selectValue, warehouse_unique_code: selectValue.warehouse_unique_code, warehouse_name: selectValue.warehouse_name})
      )
    } 

    setDisposalRequests((prevAccount) => ({ ...prevAccount, warehouseSelected: [], warehouse: []}))
  }

  const getWarehouses = async(ignore) => {
    try {
      setWarehouseLoading(true)
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (!ignore) {
          setWarehouses(response.data);
        }
        setWarehouseLoading(false)
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let ignore = false;
    if (!openWarehouse) {
      setWarehouses([]);
    }

    if (!openWarehouse) return undefined
    getWarehouses(ignore)

    return () => {
      ignore = true
    };
  }, [openWarehouse]);

  const assetDisposalStatus = [
    { label: 'For Approval', id: 0 },
    { label: 'Approved to Scrap', id: 1 },
    { label: 'Asset Disposed', id: 2 },
    { label: 'For Completion', id: 3 },
    { label: 'Closed', id: 4 },
  ];

  const handleStatusChange = (event, newValue) => {
    if (newValue) {
      const { id, label } = newValue;

      // Update the selected status
      setDisposalRequests((prevAccount) => ({
        ...prevAccount,
        status_arr: newValue,
        status: id, 
        status_name: label,
      }))
    }
  };

  const handleFASRChange = (event) => {
    const file = event.target.files[0];
    setDisposalRequests((prev) => ({...prev, fasr_file: file}));
  };

  const handleReceiptChange = (event) => {
    const file = event.target.files[0];
    setDisposalRequests((prev) => ({...prev, receipts: file}));
  };

  return (
    <>
      <Button variant="contained" size="small" onClick={handleOpen}>Create Request</Button>
      <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
      >
      <DialogTitle>Add New Disposal request</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={1}>
        <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                id="combo-box-demo"
                open={openWarehouse}
                onOpen={() => {setOpenWarehouse(true);}}
                onClose={() => {setOpenWarehouse(false);}}
                loading={warehouseLoading}
                onChange={hanldeWarehouseChange}
                options={warehouses}
                value={warehouses.label}
                noOptionsText={'No Available Data'}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Warehouse Branch*"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
              <FormControl fullWidth sx={{ marginTop: 2 }}>
              <Autocomplete
                disablePortal
                options={assetDisposalStatus}
                value={disposalRequests.status_arr}
                onChange={handleStatusChange}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option.label === value.label}
                renderInput={(params) => <TextField {...params} label="Request Status" />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Date approved"
                        value={disposalRequests.date_approved}
                        onChange={(newValue) => setDisposalRequests({...disposalRequests, date_approved: dayjs(newValue).format('YYYY-MM-DD')})}
                    />
                </LocalizationProvider>
            </FormControl>
          </Grid>
          
            <Grid item xs={12}>
              <Typography>Remarks:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Input Remarks" 
                  onChange={(e) => setDisposalRequests((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    name="name"
                    label="Scrap Buyer*"
                    fullWidth
                    variant="standard"
                    onChange={(e) => setDisposalRequests((prevAccount) => ({ ...prevAccount, scrap_buyer: e.target.value }))}
                    value={disposalRequests.scrap_buyer}
                />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name="name"
                label="Scrap proceeds*"
                fullWidth
                variant="standard"
                onChange={(e) => {
                  const input = e.target.value;
                  // Regular expression to allow up to 11 digits
                  if (/^\d{0,11}$/.test(input)) {
                    setDisposalRequests((prevData) => ({ ...prevData, scrap_proceeds: input }));
                  }
                }}
                value={disposalRequests.scrap_proceeds}
              />
            </Grid>

            <Grid item xs={12}>
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                        label="Date disposed"
                        value={disposalRequests.date_disposed}
                        onChange={(newValue) => setDisposalRequests({...disposalRequests, date_disposed: dayjs(newValue).format('YYYY-MM-DD')})}
                    />
                </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid container item xs={12} alignItems="center" spacing={2} >
            <Grid item>
              <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                Upload FASR*
                <VisuallyHiddenInput type="file" accept="application/pdf, image/*" onChange={handleFASRChange}/>
              </Button>
            </Grid>
            <Grid item>
              {disposalRequests.fasr_file && (
                <p>{disposalRequests.fasr_file.name}</p>
              )}
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems="center" spacing={2} >
            <Grid item>
              <Button component="label" variant="contained" startIcon={<CloudUpload />}>
                Upload Receipts*
                <VisuallyHiddenInput type="file" accept="application/pdf, image/*" onChange={handleReceiptChange}/>
              </Button>
            </Grid>
            <Grid item>
              {disposalRequests.receipts && (
                <p>{disposalRequests.receipts.name}</p>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} mb={2}>
              <Typography mb={1}>Scrap asset picture*:</Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
                >
                Upload images
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
              {disposalRequests.asset_image.length > 0 && (
                <>
                  <Typography mt={2}>Selected Image:</Typography>
                  <ul>
                    {disposalRequests.asset_image.map((file, index) => (
                      <li key={index}>
                        {file.name}
                        <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                          <Close />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button 
                variant='contained' 
                color='primary' 
                style={{ marginBottom: '8px' }} 
                onClick={handleOpenSignature}>
                Signature
              </Button>
              <Dialog open={openSignature} onClose={() => setOpenSignature(false)}>
                <DialogTitle>Add signature</DialogTitle>
                <DialogContent>
                <ReactSignatureCanvas 
                  penColor='black'
                  canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} 
                  ref={signatureRef}
                />
                </DialogContent>
                <DialogActions>
                  <Button 
                      onClick={handleSaveSignature} 
                      variant='contained'
                      sx={{
                            color: theme.palette.primary.main,
                            bgcolor: '#7acda1',
                            '&:hover': {
                              bgcolor: '#ACE1C3',
                            },
                          }}  
                      >Done</Button>
                  <Button onClick={handleClearSignature} color="primary">clear</Button>
                  <Button onClick={() => setOpenSignature(false)} color="error">cancel</Button>
                </DialogActions>
              </Dialog>
            </Grid>

        </Grid>
      </DialogContent>
      <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
        <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default AddDisposalRequest