import { Box, Breadcrumbs, Chip, Grid, StyledEngineProvider, Typography } from '@mui/material'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { format } from 'date-fns';
import React, { useEffect } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import { useState } from 'react';
import MessageDialog from '../Utils/MessageDialog';
// import AddJobOrder from './AddJobOrder';
import { Link } from 'react-router-dom';
import CustomNoRowsOverlay from '../Utils/CustomNoRowsOverlay';
import AddDisposalRequest from './AddDisposalRequest';
import useFetchColumns from '../../hooks/UseFetchColumns';
import useFilteredAndOrderedColumns from '../../hooks/UseFilteredAndOrderedColumns';
import ColumnSettingsButton from '../Utils/ColumnSettingsButton';
import CustomToolbar from '../Utils/CustomToolbar';
import useFetchPageLength from '../../hooks/UseFetchPageLength';
import CustomGlobalFooter from '../Utils/CustomGlobalFooter';
import useSortModel from '../../hooks/UseSortModel';
import WarehouseBranchTooltip from '../AssetRegistry/WarehouseBranchTooltip';
import AssetDetailsTooltip from '../DeploymentRequest/AssetDetailsTooltip';
// import AddRepairRequest from './AddRepairRequest';




const AssetDisposal = () => {
  const axios = AxiosCustomConfig();
  const [onEdit, setOnEdit] = useState(false);
  const [assetDisposalData, setAssetDisposalData] = useState([]);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [onSaveColumns, setOnSaveColumns] = useState(false);
  const functionName = 'asset_disposal';
  const modelTable = 'AssetDisposalRequests';
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const { paginationModel, setPaginationModel, pageSizeOptions } = useFetchPageLength(functionName, setOpenMessageDialog, setMessage, onSaveColumns);

  const header_columns = [
    { field: 'id', headerName: 'Id', minWidth: 50, wrap: true,
    },
    { field: 'request_code', headerName: 'Disposal #',flex: 1, minWidth: 150, wrap: true,
      renderCell: (params) => {
          const requestCode = params.value; // Get the value of request_code for the current row
          // Render the request_code as a clickable link using an anchor tag
          const encodedRequestCode = encodeURIComponent(requestCode);

          return (
              <Link to={`/assetDisposal/${encodedRequestCode}`}>
                  {requestCode}
              </Link>
          );
      }  
    },
    { 
      field: 'status', 
      headerName: 'Scrapping Status',
      flex: 1, 
      minWidth: 100, 
      wrap: true,
      renderCell: (params) => {
        
          const statusName = params.value;
          if (statusName === 0) {
            return <Chip label='For Approval' sx={{ backgroundColor: '#35A7CA' }} />;
          } else if (statusName === 1) {
            return <Chip label='Approved to scrap' sx={{ backgroundColor: '#D7D6D4' }} />;
          } else if (statusName === 2) {
            return <Chip label='Asset disposed' sx={{ backgroundColor: '#3AD658' }} />;
          } else if (statusName === 3) {
            return <Chip label='For completion' sx={{backgroundColor: '#F8FD6A', color: '#000'}} />;
          } else if (statusName === 4) {
            return <Chip label='Closed' color='error' />;
          }  else {
            return '';
          }
          
      }
  },
    { field: 'warehouse_name', headerName: 'Warehouse Branch',flex: 1, minWidth: 150,
      renderCell: (params) => {
        const warehouse_name = params.value;
        const warehouse_id = params.row.warehouse_id;
        return (
          <WarehouseBranchTooltip id={warehouse_id} value={warehouse_name} />
        );
      }
    },
    { field: 'assets', headerName: 'Assets',flex: 1, minWidth: 150,
      renderCell: (params) => {
        if(params.value) {
          const assets = params.value.split(',').map(service => service.trim());
          const asset_ids = params.row.assets_array;
          return (
              <>
                <Grid container direction='column'>
                {assets.map((asset, index) => (
                  <AssetDetailsTooltip
                    key={index} // Add a unique key for React rendering
                    id={asset_ids[index]?.asset_id || ''} // Use the asset_id from the corresponding index
                    value={asset_ids[index]?.name} // Display the asset value
                  />
                ))}
                </Grid>
              </>
          );
        }
        return null
    },
    },
    { field: 'created_by', headerName: 'Created by',flex: 1, minWidth: 150,},
    { field: 'date_modified', headerName: 'Date Updated',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        const date = new Date(params);
        return format(date, 'yyyy-MM-dd');
      }, wrap: true 
    },
    { field: 'date_approved', headerName: 'Date Approved',flex: 1, minWidth: 150,
      valueFormatter: (params) => {
        if(params) {
          const date = new Date(params);
          return format(date, 'yyyy-MM-dd');
        }
        return '';
      }, wrap: true 
    },
    { field: 'remarks', headerName: 'Remarks',flex: 1, minWidth: 150,},
  ];

  useEffect(() => {
    const getAssetTypes = async () => {
      setLoading(true)
      try {
        const options = {
          'method': 'POST',
          'url' : '/AssetsDisposal/getAllDisposalRequest.json',
        }
        const response = await axios(options);
        const data = response.data;

        setAssetDisposalData(data)
        setLoading(false)
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
      }
    }

    getAssetTypes();
  }, [onEdit]);

  const columns = useFetchColumns(functionName, onSaveColumns, setOpenMessageDialog, setMessage);
  const filteredAndOrderedColumns = useFilteredAndOrderedColumns(columns, header_columns);
  const [sortModel, setSortModel] = useSortModel(columns);

  const handleClickAllData = async () => {
    if(!selectAll) {
      try {
        setLoading(true);
        const options = {
          method: 'POST',
          url: '/AssetsDisposal/getAllDisposalRequestId.json',
        };
        const response = await axios(options);
        
        const allDataID = response.data.map((row) => row.id);
        
        setRowSelectionModel(allDataID);
        setSelectAll(true);
      } catch (error) {
        setOpenMessageDialog(true);
        setMessage(`${error.toJSON().message}. Please try again and make sure you have an internet connection.`);
      } finally {
        setLoading(false);
      }
    } else {
      setRowSelectionModel([]);
      setSelectAll(false);
    }
  }

  return (
    <>
    <Box sx={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center'  }}>
      <Box sx={{ display: 'flex', gap: '10px' }}>
      <AddDisposalRequest setOnEdit={setOnEdit}/>
      </Box>
      
      {/* Spacer */}
      <Box sx={{ flexGrow: 1 }} />

      {/* Settings Icon and Menu */}
      <Box sx={{ position: 'relative' }}>
        <ColumnSettingsButton
          columns={columns}
          setOnSaveColumns={setOnSaveColumns}
          functionName={functionName}
          paginationModel={paginationModel}
        />
      </Box>
    </Box>
    <Box sx={{width: '100%', display:'table', tableLayout: 'fixed'}}>
      <DataGrid
        keepNonExistentRowsSelected 
        rows={assetDisposalData}
        columns={filteredAndOrderedColumns}
        getRowHeight={() => 'auto'}
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: () => <CustomToolbar 
              selectedRows={rowSelectionModel} 
              columns={columns}
              setOnSaveColumns={setOnSaveColumns}
              functionName={functionName}
              modelTable={modelTable}
              handleClickAllData={handleClickAllData}
              selectAll={selectAll}
            />,
            footer: () => <CustomGlobalFooter selectedRows={rowSelectionModel.length}/>,
        }}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        sortModel={sortModel} 
        onSortModelChange={(model) => setSortModel(model)}
        sortingOrder={['asc', 'desc']}
        disableMultipleColumnsSorting={false}
        disableRowSelectionOnClick
        autoHeight
        loading={loading}
        sx={{
          [`& .${gridClasses.cell}`]: {
            py: 1,
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: 1,
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '10px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '10px',
          },
          '& .MuiDataGrid-columnHeaderCheckbox': {
            opacity: 0.5, 
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }
        }}
      />
    </Box>

    <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => setOpenMessageDialog(false)}
          message={message}
        />
    </StyledEngineProvider>
  </>
  )
}

export default AssetDisposal