import { useTheme } from '@emotion/react';
import { CheckCircleOutline, Close, CloudUpload } from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, StyledEngineProvider, Typography } from '@mui/material'
import React, { useRef } from 'react'
import { useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas';
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import useAssetDisposalContext from '../../hooks/UseAssetDisposalContext';
import { validateFileExtensions, validateFileSizes, VisuallyHiddenInput } from '../GenericFunctions/GenericFunctions';


function ApproveAssetDisposal({disposalDetails, setonRejectOrApprove}) {
  const theme = useTheme();
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useAssetDisposalContext();
  const [open, setOpen] = useState(false);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const signatureRef = useRef();
  const [requests, setRequests] = useState({
    id: '',
    request_code: '',
    signature_url: '',
    asset_image: '',
  });
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const handleOpen = () => {
    setOpen(true)
    const { request_code, id, asset_code} = disposalDetails;
    setRequests((prevData) => ({ 
      ...prevData, 
      request_code: request_code, 
      id: id,
      asset_code: asset_code,
    }))
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleContinueButton = () => {
    const { isValid, invalidFiles } = validateFileSizes(requests.asset_image);
    const { isValidExtension, invalidFilesExtension } = validateFileExtensions(requests.asset_image);
    
    if (!isValid && requests.asset_image.length > 0) {
      // Collect names of files that exceed the size limit
      const fileNames = invalidFiles.map((file) => file.name).join(', ');
      setOpenMessageDialog(true);
      setMessage(`The following file(s) exceed the 2 MB limit: ${fileNames}. Please upload smaller files.`);
      return false;
    }

    if (!isValidExtension && requests.asset_image.length > 0) {
      const invalidFileNames = invalidFilesExtension.map((file) => file.name).join(', ');
      setOpenMessageDialog(true);
      setMessage(`The following file(s) are not image files: ${invalidFileNames}. Please upload valid image files.`);
      return false;
    }


    setOpenSignatureDialog(true)
  }

  const handleCancelSubmit = () => {
    setOpenSignatureDialog(false)
    setOpen(true)
  }

  const handleClearSignature = () => {
    signatureRef.current.clear();
  };

  const handleSaveSignature = () => {
    const dataURL = signatureRef.current.toDataURL('image/png');
    
    if (dataURL) {
      setRequests(prevData => {
        const updatedData = { ...prevData, signature_url: dataURL };
        processAndSendRequest(updatedData);
        return updatedData;
      });

      handleClose()
    }
    
  };

  const processAndSendRequest = async (requestData) => { 
    try{
      const formData = new FormData();
      if (requestData.asset_image.length >  0) {
        requestData.asset_image.forEach((file, index) => {
          formData.append(`asset_image_${index}`, file);
        });
      }

      // Add other fields to formData if needed
      formData.append('request_code', requestData.request_code);
      formData.append('id', requestData.id);
      formData.append('signature_url', requestData.signature_url);

      setLoading(true);
      setShowProgress(true);
      const options = {
          'method': 'POST',
          'url' : '/AssetsDisposal/approveDisposalRequest.json',
          'data': formData
        }
        const response = await axios(options);
        const data = response.data;
        if (data.is_success) {
          setDoneUpdate(prev => !prev)
          setOpenSignatureDialog(false)
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        } else {
          setShowProgress(false);
          setOpenMessageDialog(true);
          setMessage(data.message);
        }
        
    } catch (error) {
        setShowProgress(false);
        setOpenMessageDialog(true);
        setMessage(` ${error.toJSON().message}. Network error.`);
    } finally {
        setLoading(false);
        setShowProgress(false);
    }
  };


  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setRequests((prevAccount) => ({ ...prevAccount, asset_image: [...prevAccount.asset_image, ...files]}))
  };


  const removeImage = (indexToRemove) => {
    // Create a new array excluding the image at the specified index
    const updatedImages = requests.asset_image.filter((_, index) => index !== indexToRemove);
    
    // Update the state with the new array of images
    setRequests(prevState => ({
      ...prevState,
      asset_image: updatedImages
    }));
  };


  return (
    <>
      <Button 
        disabled={disposalDetails.status === 2 || disposalDetails.status === 1 ? true : false} 
        variant='contained' 
        size="small" 
        color='primary' 
        startIcon={<CheckCircleOutline />} 
        onClick={handleOpen}>
        Approve for Disposal
      </Button>
      <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
      >

      <DialogTitle>Submit for Approval</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} pt={1}>
        <Grid item xs={12} mb={2}>
              <Typography mb={1}>Scrap asset picture*:</Typography>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
                >
                Upload images
                <VisuallyHiddenInput
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Button>
              {requests.asset_image.length > 0 && (
                <>
                  <Typography mt={2}>Selected Image:</Typography>
                  <ul>
                    {requests.asset_image.map((file, index) => (
                      <li key={index}>
                        {file.name}
                        <IconButton color='error' aria-label="remove" onClick={() => removeImage(index)}>
                          <Close />
                        </IconButton>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
        <FormControl fullWidth>
            <Button variant="contained" size="small" color='success' onClick={handleContinueButton}>Continue</Button>
        </FormControl>
        <FormControl fullWidth>
          <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
        </FormControl>
      </DialogActions>
      </Dialog>



      <Dialog open={openSignatureDialog} onClose={() => setOpenSignatureDialog(false)}>
      <DialogTitle>Add signature</DialogTitle>
      <DialogContent>
      <ReactSignatureCanvas 
        penColor='black'
        canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} 
        ref={signatureRef}
      />
      </DialogContent>
      <DialogActions>
        <Button 
            onClick={handleSaveSignature} 
            variant='contained'
            sx={{
                  color: theme.palette.primary.main,
                  bgcolor: '#7acda1',
                  '&:hover': {
                    bgcolor: '#ACE1C3',
                  },
                }}  
            >Save & Approve</Button>
        <Button onClick={handleClearSignature} color="primary">clear</Button>
        <Button onClick={handleCancelSubmit} color="error">cancel</Button>
      </DialogActions>
    </Dialog>

    <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
      <StyledEngineProvider injectFirst>
        <MessageDialog
          open={openMessageDialog}
          handleClose ={() => {
            setOpenMessageDialog(false)
            setonRejectOrApprove((prev) => !prev)
          }}
          message={message}
        />
      </StyledEngineProvider>
    </>
  )
}

export default ApproveAssetDisposal