import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { AxiosCustomConfig } from '../../../config/AxiosCustomConfig';
import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import ProcessDialog from '../../Utils/ProcessDialog';
import MessageDialog from '../../Utils/MessageDialog';

export default function HaulingRate() {
    const axiosConnection = AxiosCustomConfig();

    const [rateSettings, setRateSettings] = useState({
        value: 0,
        function_name: 'hauling_rate_settings'
    });


    const [showProgress, setShowProgress] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    
    const handleCloseDialog = () => setIsDialogOpen(false);


    const handleSaveSettings = (e) => {
        e.preventDefault()
        
        setShowProgress(true);

        axiosConnection({
            url: 'GeneralSettings/updateHaulingRate.json',
            method: 'POST',
            data: rateSettings
        }).then((res) => {
            const {message} = res.data;

            setShowProgress(false);
            setIsDialogOpen(true);
            setModalMessage(message);
        }).catch(error => {
            console.log(error.response.data);
        });
    };

    const handleChange = (e) => {
        setRateSettings((prevData) => ({...prevData, value: e.target.value}))
    }

    const getHaulingRate = async (signal) => {
        try {
            const options = {
                method: 'POST',
                url: '/GeneralSettings/getHaulingRateSettings.json',
                data: rateSettings,
                signal, // Attach the signal for aborting
            };

            const response = await axiosConnection(options);
            setRateSettings(response.data);
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
            } else {
                console.error('Error fetching hauling rate settings:', error);
            }
        }
    };

    useEffect(() => {
        const controller = new AbortController(); // Create an AbortController
        const signal = controller.signal;

        getHaulingRate(signal);

        return () => {
            controller.abort(); // Cancel the request on unmount
        };
    }, []);

    return (
        <Box>
            <Alert severity="info" sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="caption">Notes:</Typography>
                <Typography variant="caption">ON: Enabling this setting allows the use of old rates. The last entered rates will be treated as the new rates before any modifications. These modified rates will then be moved to the "Old Rates" column</Typography>
                <Typography variant="caption">OFF: Disabling this setting will automatically use the most recently entered rates, bypassing the old rates entirely</Typography>
                </Box>
            </Alert>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                    <InputLabel id="rate-settings-label">Settings</InputLabel>
                        <Select
                            labelId="rate-settings-label"
                            id="rate-settings"
                            value={rateSettings.value}
                            label="Settings"
                            onChange={handleChange}
                        >
                            <MenuItem value={0}>Off</MenuItem>
                            <MenuItem value={1}>On</MenuItem>
                        </Select>
                    </FormControl>
                    
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={handleSaveSettings}>
                        Save
                    </Button>
                </Grid>
            </Grid>

            <ProcessDialog
                showProgress={showProgress}
                ContentText="Loading...."
            />
            <MessageDialog
                open={isDialogOpen}
                message={modalMessage}
                handleClose={handleCloseDialog}
            />
        </Box>
    )
}
