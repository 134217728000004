import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, StyledEngineProvider, TextField, Typography, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AxiosCustomConfig } from '../../config/AxiosCustomConfig';
import ProcessDialog from '../Utils/ProcessDialog';
import MessageDialog from '../Utils/MessageDialog';
import { isValidSpecialCharacter, objectToFormData } from '../GenericFunctions/GenericFunctions';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { Edit } from '@mui/icons-material';
import useJobOrdersContext from '../../hooks/UseJobOrdersContext';

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
  box-sizing: border-box;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
function EditJobOrder({jobOrderDetails, setDoneUpdateRequest}) {
  const axios = AxiosCustomConfig();
  const {setDoneUpdate} = useJobOrdersContext();
  const [open, setOpen] = useState(false);
  const [openMessageDialog,setOpenMessageDialog] = useState(false)
  const [message, setMessage] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [editData, setEditData] = useState({
    trip_date: new Date().toISOString().substr(0, 10),
    hauling_rates: [],
    warehouse: [],
    remarks: '',
    job_order_id: '',
    number_of_request: '',
  });


  const [haulingRates, setHaulingRates] = useState([]);
  const [openHaulingRate, setOpenHaulingRate] = useState(false);
  const haulingLoading = openHaulingRate && haulingRates.length === 0;
  const [warehouses, setWarehouses] = useState([]);
  const [openWarehouse, setOpenWarehouse] = useState(false);
  const warehouseLoading = openWarehouse && warehouses.length === 0;

  const handleOpen = () => {
    setOpen(true)

    const {trip_date, remarks, warehouse_unique_code, centigen_branch, hauling_rate, hauling_rate_name, hauling_unique_code, job_order_id, number_of_request} = jobOrderDetails

    setEditData(prevData => ({
      ...prevData,
      trip_date: trip_date,
      remarks: remarks,
      warehouse: {'label': `${centigen_branch}`, 'warehouse_name': centigen_branch, warehouse_unique_code: warehouse_unique_code}, 
      hauling_rates: {'label': hauling_rate_name, 'unique_code': hauling_unique_code, 'rate': hauling_rate},
      warehouse_unique_code: warehouse_unique_code,
      job_order_id: job_order_id,
      number_of_request: number_of_request,
    }))
    
  }


  const handleClose = () => {
    resetForm()
    setOpen(false)
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateRequiredFields()) return;

    if (!isValidSpecialCharacter(editData.remarks)) {
      setOpenMessageDialog(true);
      setMessage('Invalid Special Characters found in remarks.')
      return
    }

    setShowProgress(true);
    try {
        
        const options = {
            method: 'POST',
              url: '/JobOrders/editJobOrder.json',
              data: editData,
        }

        const response = await axios(options);
        setShowProgress(false);
        if (response.data.is_success) {
          resetForm();
          setDoneUpdateRequest((prev) => !prev)
          setDoneUpdate((prev) => !prev)
          setOpen(false)
          setOpenMessageDialog(true);
          setMessage(response.data.message)
        } else {
          setShowProgress(false)
          setOpenMessageDialog(true)
          setMessage(response.data.message)
        }

    } catch (error) {
      setShowProgress(false)
      setOpenMessageDialog(true);
      setMessage(` ${error.toJSON().message}. Network error.`);
    }
  }

  const resetForm = () => {
    setEditData({
      trip_date: new Date().toISOString().substr(0, 10),
      hauling_rates: [],
      warehouse: [],
      remarks: '',
      job_order_id: '',
      number_of_request: '',
    });
  };


  const validateRequiredFields = () => {
    const requiredFields = ['trip_date',  'hauling_rates', 'warehouse', 'number_of_request'];

    for (const field of requiredFields) {
      if (!editData[field] || (Array.isArray(editData[field]) && editData[field].length === 0)) {
        setOpenMessageDialog(true);
        setMessage('All Fields with asterisk (*) are required');
        return false;
      }
    }

    return true;
  };

  const getHaulingRates = async (active) => {
    try {
        const options = {
            method: 'POST',
            url: '/HaulingRates/getHaulingRatesForAutocomplete.json',
        }
        const response = await axios(options);
        if (active) {
          setHaulingRates(response.data);
        }
        
    } catch (error) {
      console.log('Error info: ', error);
    }
  }
  const getWarehouses = async (active) => {
    try {
        const options = {
            method: 'POST',
            url: '/Warehouses/getWarehousesForAutocomplete.json',
        }
        const response = await axios(options);
        if (active) {
          setWarehouses(response.data);
        }
        
    } catch (error) {
      console.log('Error info: ', error);
    }
  }

  useEffect(() => {
    let active = true;

    if (!haulingLoading) {
      return undefined;
    }

    getHaulingRates(active);

    return () => {
      active = false;
    };

  }, [haulingLoading]);

  useEffect(() => {
    if (!openHaulingRate) {
      setHaulingRates([]);
    }
  }, [openHaulingRate]);

  useEffect(() => {
    let active = true;

    if (!warehouseLoading) {
      return undefined;
    }

    getWarehouses(active);

    return () => {
      active = false;
    };

  }, [warehouseLoading]);

  useEffect(() => {
    if (!openWarehouse) {
      setWarehouses([]);
    }
  }, [openWarehouse]);

  const hanldeHaulingRequestsChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevAccount) => ({ ...prevAccount, hauling_rates: selectValue}))
    }
  }

  const hanldeWarehouseChange = (e, selectValue) => {
    if (selectValue) {
      setEditData((prevAccount) => ({ ...prevAccount, warehouse: selectValue}))
    } else {
      setEditData((prevAccount) => ({ ...prevAccount, warehouse: []}))
    }
  }


  return (
    <div>
    <Button 
      disabled={jobOrderDetails.status === 0 ? false : true} 
      variant='outlined' 
      size="small" 
      color='info' 
      startIcon={<Edit />}  
      onClick={handleOpen}>Edit</Button>
    <Dialog
      open={open}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          handleClose();
        },
      }}
      fullWidth={true} 
      maxWidth={'md'}
    >
      <DialogTitle>Update Job order</DialogTitle>
      <DialogContent>
          <Grid container spacing={2}>
          <Grid item xs={12} mt={1}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker 
                            label="Trip Date*"
                            value={editData.trip_date ? dayjs(editData.trip_date) : null}
                            onChange={(newValue) => setEditData({...editData, trip_date: dayjs(newValue).format('YYYY-MM-DD')})}
                            minDate={dayjs()}
                        />
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openWarehouse}
                      onOpen={() => {setOpenWarehouse(true);}}
                      onClose={() => {setOpenWarehouse(false);}}
                      onChange={hanldeWarehouseChange}
                      options={warehouses}
                      value={editData.warehouse}
                      loading={warehouseLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Warhouse*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {warehouseLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      open={openHaulingRate}
                      onOpen={() => {setOpenHaulingRate(true);}}
                      onClose={() => {setOpenHaulingRate(false);}}
                      onChange={hanldeHaulingRequestsChange}
                      options={haulingRates}
                      value={editData.hauling_rates}
                      loading={haulingLoading}
                      getOptionLabel={(option) => option.label || ''}
                      isOptionEqualToValue={(option, value) => option.label === value.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Hauling Rate*"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {haulingLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                  name="number_of_request"
                  label="No. of SN*"
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                      const input = e.target.value;
                      // Regular expression to allow numbers with optional decimal point
                      if (/^\d*\.?\d*$/.test(input)) {
                          const numericValue = parseFloat(input);
                          // Check if the numeric value is within the range [0, 1000000]
                          if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 1000000) {
                            setEditData((prevAccount) => ({ ...prevAccount, number_of_request: input }));
                          }
                      }
                  }}
                  value={editData.number_of_request}
              />
              <Typography variant="subtitle2" color="initial">Number of SN in this trip</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>Remarks:</Typography>
                <Textarea 
                  aria-label="minimum height" 
                  minRows={3} 
                  placeholder="Remarks" 
                  onChange={(e) => setEditData((prevAccount) => ({ ...prevAccount, remarks: e.target.value }))}
                  value={editData.remarks}
                />
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{paddingLeft: 3, paddingRight: 3, marginBottom: 2}}>
          <FormControl fullWidth>
              <Button variant="contained" size="small" color='success' onClick={handleSubmit}>Submit</Button>
          </FormControl>
          <FormControl fullWidth>
            <Button variant="outlined" size="small" color='error' onClick={handleClose} autoFocus>Cancel</Button>
          </FormControl>
        </DialogActions>
      </Dialog>

      <ProcessDialog showProgress={showProgress} ContentText="Loading Please Wait..."/>
        <StyledEngineProvider injectFirst>
          <MessageDialog
            open={openMessageDialog}
            handleClose ={() => setOpenMessageDialog(false)}
            message={message}
          />
      </StyledEngineProvider>

    </div>
  )
}

export default EditJobOrder